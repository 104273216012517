import {Calendar} from './components/Calendar/Calendar';
import {StageSelect} from './components/StageSelect';
import styles from './ScheduleForm.module.less';
import {editableScheduleFields} from './constants/editableScheduleFields';
import {ProcessSelect} from './components/ProcessSelect';
import {ObjectsSelect} from './components/ObjectsSelect';
import {ParticipantsSelect} from './components/ParticipantsSelect';
import {DateRange} from './components/DateRange/DateRange';
import {SimultaneousSlots} from './components/SimultaneousSlots';
import {SlotDurationSelect} from './components/SlotDurationSelect';
import {SlotsIntervalSelect} from './components/SlotsIntervalSelect';
import {MaxPreregistrationPeriodSelect} from './components/MaxPreregistrationPeriodSelect';
import {MinPreregistrationPeriodSelect} from './components/MinPreregistrationPeriodSelect';
import {MaxOccupiedSlots} from './components/MaxOccupiedSlots/MaxOccupiedSlots';
import {SetValueOptions} from '@/shared/types/SetValueOptions';
import {IScheduleForm} from './types/IScheduleForm';
import {ScheduleFieldValueType} from './types/IScheduleFieldProps';
import {CompanySelect} from './components/CompanySelect';
import {createErrorMessages} from './utils/createErrorMessages';

interface IScheduleFormProps {
	changeHandler: (
		name: keyof IScheduleForm,
		value?: ScheduleFieldValueType,
		options?: SetValueOptions
	) => void;
}

export const ScheduleForm = ({changeHandler}: IScheduleFormProps) => {
	const errors = createErrorMessages();
	return (
		<div>
			<p className={styles.title}>Общие параметры</p>
			<div className={styles.form}>
				<Calendar
					onSetValue={changeHandler}
					field={editableScheduleFields.CALENDAR_ID}
					className={styles.formItem}
				/>
				<StageSelect
					onSetValue={changeHandler}
					field={editableScheduleFields.STAGE_ID}
					required
					className={styles.formItem}
					errorMessage={errors.stageId}
				/>
				<ProcessSelect
					field={editableScheduleFields.PROCESS_ID}
					onSetValue={changeHandler}
					required
					className={styles.formItem}
					errorMessage={errors.processId}
				/>
				<CompanySelect
					onSetValue={changeHandler}
					field={editableScheduleFields.COMPANY_ID}
					required
					className={styles.formItem}
					errorMessage={errors.companyId}
				/>
				<ObjectsSelect
					onSetValue={changeHandler}
					field={editableScheduleFields.OBJECT_IDS}
					className={styles.formItem}
				/>
				<ParticipantsSelect
					onSetValue={changeHandler}
					field={editableScheduleFields.PARTICIPANT_IDS}
					required
					className={styles.formItem}
					errorMessage={errors.participantIds}
				/>
				<DateRange
					onSetValue={changeHandler}
					fieldDateFrom={editableScheduleFields.DATE_RANGE_FROM}
					fieldDateTo={editableScheduleFields.DATE_RANGE_TO}
					className={styles.formItem}
				/>
				<SimultaneousSlots
					onSetValue={changeHandler}
					field={editableScheduleFields.SIMULTANEOUS_SLOTS_COUNT}
					required
					className={styles.formItem}
					errorMessage={errors.simultaneousSlotsCount}
				/>
				<SlotDurationSelect
					onSetValue={changeHandler}
					field={editableScheduleFields.SLOT_DURATION}
					required
					className={styles.formItem}
				/>
				<SlotsIntervalSelect
					onSetValue={changeHandler}
					field={editableScheduleFields.INTERVAL_BETWEEN_SLOTS}
					required
					className={styles.formItem}
				/>
				<MaxOccupiedSlots
					onSetValue={changeHandler}
					field={editableScheduleFields.MAXIMUM_OCCUPIED_SLOTS_NUMBER}
					className={styles.formItem}
					errorMessage={errors.maximumOccupiedSlotsNumber}
				/>
				<MaxPreregistrationPeriodSelect
					onSetValue={changeHandler}
					field={editableScheduleFields.MAXIMUM_PREREGISTRATION_PERIOD}
					className={styles.formItem}
				/>
				<MinPreregistrationPeriodSelect
					onSetValue={changeHandler}
					field={editableScheduleFields.MINIMUM_PREREGISTRATION_PERIOD}
					className={styles.formItem}
				/>
			</div>
		</div>
	);
};
