import {IGetScheduleResponse} from '@/shared/api/schedule/getSchedule';
import {schedulesQueryKeys} from '../../keys';
import {useSchedulesPermissions} from '@/shared/hooks/permissions/useSchedulesPermissions';
import {useQuery} from '@tanstack/react-query';
import IError from '@tehzor/tools/interfaces/IError';

export const useSchedule = (scheduleId?: string) => {
	const {canViewSchedules} = useSchedulesPermissions();

	return useQuery<IGetScheduleResponse, IError>({
		queryKey: schedulesQueryKeys.detail(scheduleId),
		meta: {
			error: 'при загрузке расписания'
		},
		enabled: !!canViewSchedules && !!scheduleId
	});
};
