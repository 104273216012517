import {FieldNamesMarkedBoolean} from 'react-hook-form';
import {IScheduleForm, ScheduleFieldValueType} from '@/features/EditableSchedule';
import {ISavingSchedule} from '@tehzor/tools/interfaces/schedules';

export const getPreparedMainInfoFormData = (
	scheduleId: string | undefined,
	data: IScheduleForm,
	dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<IScheduleForm>>>
): Partial<ISavingSchedule> => {
	if (!scheduleId) {
		return {
			...data,
			dateRangeFrom: data.dateRangeFrom ? new Date(data.dateRangeFrom).getTime() : undefined,
			dateRangeTo: data.dateRangeTo ? new Date(data.dateRangeTo).getTime() : undefined,
			objectIds: data.objectIds?.length ? data.objectIds : undefined
		};
	}
	const updatedFields = Object.fromEntries(
		Object.keys(dirtyFields).map(key => {
			let value = data[key as keyof IScheduleForm] as ScheduleFieldValueType | null;

			if (value === undefined) {
				value = null;
			}
			if (key === 'dateRangeFrom' || key === 'dateRangeTo') {
				value = value ? new Date(value as Date).getTime() : null;
			}
			return [key, value];
		})
	) as Partial<ISavingSchedule>;

	return updatedFields;
};
