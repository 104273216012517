import {Wrapper} from '@/shared/ui/EntitiesPage/Wrapper/Wrapper';
import {SchedulePageBreadcrumbs} from './ui/SchedulePageBreadcrumbs/SchedulePageBreadcrumbs';
import {Schedule} from '@/widgets/Schedule';

export const SchedulePage = () => (
	<Wrapper>
		<SchedulePageBreadcrumbs />
		<Schedule />
	</Wrapper>
);
