import {useMutation} from '@tanstack/react-query';
import {ISavingSchedule} from '@tehzor/tools/interfaces/schedules';
import {schedulesQueryKeys} from '../cache/keys';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';
import {queryClient} from '@src/api/QueryClient';
import {editScheduleRequest} from '@/shared/api/schedule/editSchedule';

export const useEditSchedule = () =>
	useMutation({
		mutationFn: ({scheduleId, fields}: {scheduleId: string; fields: ISavingSchedule}) =>
			editScheduleRequest(scheduleId, fields),
		mutationKey: schedulesQueryKeys.edit(),
		onSuccess: () => {
			addSuccessToast({title: 'Расписание успешно изменено'});
			void queryClient.invalidateQueries({queryKey: schedulesQueryKeys.list()});
			void queryClient.invalidateQueries({queryKey: schedulesQueryKeys.one()});
		},
		onError: () => addErrorToast({title: 'Ошибка при редактировании расписания'})
	});
