import {Plate, Scrollbar} from '@tehzor/ui-components';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {
	IScheduleForm,
	ScheduleFieldValueType,
	ScheduleForm,
	useDefaultScheduleFormData,
	editableScheduleFields,
	createErrorMessages,
	ParticipantsErrorTypes
} from '@/features/EditableSchedule';
import {FormProvider, useForm} from 'react-hook-form';
import {getPreparedMainInfoFormData} from './model/utils/getPreparedMainInfoFormData';
import {SetValueOptions} from '@/shared/types/SetValueOptions';
import {useCallback} from 'react';
import styles from './Schedule.module.less';
import {ActionsButtons} from './ui/ActionsButtons/ActionsButtons';
import {useAddSchedule, useEditSchedule} from '@/entities/Schedules';
import {ScheduleErrorMessage} from '@tehzor/tools/enums/ScheduleErrorMessage';

const simpleBarProps = {autoHide: false};

export const Schedule = () => {
	const {scheduleId} = useStrictParams<{
		scheduleId?: string;
	}>();
	const {pushPath} = useChangePath();
	const {mutateAsync: addSchedule, isSuccess: isAddingSuccess} = useAddSchedule();
	const {mutateAsync: editSchedule, isSuccess: isEditingSuccess} = useEditSchedule();
	const defaultValues = useDefaultScheduleFormData(scheduleId);

	const methods = useForm<IScheduleForm>({
		defaultValues,
		values: defaultValues,
		shouldUnregister: false
	});

	const {
		setValue,
		getValues,
		trigger,
		formState: {dirtyFields, isDirty},
		reset,
		setError
	} = methods;

	const handleReset = () => reset(defaultValues);
	const errorMessages = createErrorMessages();

	const handleSubmit = async () => {
		const isMainInfoValid = await trigger();
		if (!isMainInfoValid) {
			return;
		}
		const values = getValues();
		try {
			const preparedData = getPreparedMainInfoFormData(scheduleId, values, dirtyFields);
			if (scheduleId) {
				await editSchedule({scheduleId, fields: preparedData});
				if (isEditingSuccess) {
					handleReset();
				}
			} else {
				const result = await addSchedule(preparedData);
				pushPath(`/manage/schedules/${result.id}`);
				if (isAddingSuccess) {
					handleReset();
				}
			}
		} catch (error) {
			if (error?.message === ScheduleErrorMessage.OVERLAP_SCHEDULES_BY_DATE) {
				setError(editableScheduleFields.PARTICIPANT_IDS, {
					type: ParticipantsErrorTypes.OVERLAP,
					message: errorMessages.overlapParticipants
				});
			}
		}
	};

	const changeHandler = useCallback(
		(name: keyof IScheduleForm, value?: ScheduleFieldValueType, options?: SetValueOptions) => {
			const defaultOptions = {shouldDirty: true, shouldValidate: true};
			setValue(name, value, options || defaultOptions);
		},
		[setValue]
	);

	return (
		<Plate className={{root: styles.plate, content: styles.plateContent}}>
			<Scrollbar
				className={styles.scrollBar}
				simpleBarProps={simpleBarProps}
			>
				<div className={styles.container}>
					<FormProvider {...methods}>
						<ScheduleForm changeHandler={changeHandler} />
					</FormProvider>
				</div>
			</Scrollbar>
			<ActionsButtons
				handleSubmit={handleSubmit}
				handleReset={handleReset}
				disabled={!isDirty}
			/>
		</Plate>
	);
};
