import {IDurationMeasure, durationMeasureKeys} from '@/shared/types/IDurationMeasure';
import {useDurationMeasures} from '@/shared/ui/DurationSelectDialog/hooks/useDurationMeasures';
import {TimeDurationSelect} from '@/shared/ui/Form/TimeDurationSelect/TimeDurationSelect';
import {useEffect, useState} from 'react';
import {useController, useFormContext} from 'react-hook-form';
import {useUpdateEffect} from 'react-use';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {defaultTimeValues} from '../hooks/useDefaultScheduleFormData';
import {IScheduleForm} from '../types/IScheduleForm';
import {getIntegerErrorMessage} from '../utils/getIntegerErrorMessage';

const durationValues = [43_200, 86_400, 129_600];

const getDialogError = (duration: number, durationMeasure?: IDurationMeasure) => {
	const integerErrorMessage = getIntegerErrorMessage(duration);
	if (integerErrorMessage) {
		return integerErrorMessage;
	}
	if (durationMeasure?.key === durationMeasureKeys.DAY && (duration > 365 || duration < 1)) {
		return 'Введите от 1 до 365 дней';
	}

	return undefined;
};

export const MaxPreregistrationPeriodSelect = ({
	field,
	onSetValue,
	className
}: IScheduleFieldProps) => {
	const {control} = useFormContext<IScheduleForm>();
	const {
		field: {value}
	} = useController({
		name: field,
		control
	});
	const measuresOptions = [durationMeasureKeys.DAY];
	const durationMeasures = useDurationMeasures({
		availableOptions: measuresOptions
	});

	const [duration, setDuration] = useState<number>(Number(value));
	const [durationMeasure, setDurationMeasure] = useState<IDurationMeasure>(durationMeasures.day);

	// установка дефолтного значения после получения данных с бэка
	useEffect(() => {
		if (value !== undefined && duration === defaultTimeValues.maximumPreregistrationPeriod) {
			setDuration(Number(value));
		}
	}, [value]);

	useUpdateEffect(() => {
		onSetValue(field, duration);
	}, [duration, onSetValue]);

	return (
		<TimeDurationSelect
			value={Number(value)}
			dialogError={getDialogError}
			label="Максимальный срок предварительной записи в слот"
			duration={duration}
			setDuration={setDuration}
			durationMeasure={durationMeasure}
			setDurationMeasure={setDurationMeasure}
			durationMeasures={durationMeasures}
			durationOptions={durationValues}
			disableDurationMeasure
			className={className}
		/>
	);
};
