import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingSchedule, ISchedule} from '@tehzor/tools/interfaces/schedules';

/**
 * Редактирует расписание
 *
 * @param scheduleId id расписания
 * @param fields данные расписания
 */
export const editScheduleRequest = async (scheduleId: string, fields: ISavingSchedule) => {
	const response = await httpRequests.withToken.put<ISchedule>(`schedules/${scheduleId}`, {
		...fields
	});
	return response.data;
};
