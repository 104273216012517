import {useController, useFormContext} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {useUpdateEffect} from 'react-use';
import {IDurationMeasure, durationMeasureKeys} from '@/shared/types/IDurationMeasure';
import {TimeDurationSelect} from '@/shared/ui/Form/TimeDurationSelect/TimeDurationSelect';
import {useDurationMeasures} from '@/shared/ui/DurationSelectDialog/hooks/useDurationMeasures';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {defaultTimeValues} from '../hooks/useDefaultScheduleFormData';
import {IScheduleForm} from '../types/IScheduleForm';
import {getIntegerErrorMessage} from '../utils/getIntegerErrorMessage';

const durationValuesInMinutes = [15, 30, 60, 90, 120];

const getDialogError = (duration: number, durationMeasure?: IDurationMeasure) => {
	const integerErrorMessage = getIntegerErrorMessage(duration);
	if (integerErrorMessage) {
		return integerErrorMessage;
	}
	if (durationMeasure?.key === durationMeasureKeys.MINUTE && (duration > 240 || duration < 5)) {
		return 'Введите от 5 до 240 минут';
	}
	if (durationMeasure?.key === durationMeasureKeys.HOUR && (duration > 4 || duration < 1)) {
		return 'Введите от 1 до 4 часов';
	}
	return undefined;
};

export const SlotsIntervalSelect = ({
	field,
	onSetValue,
	className,
	required
}: IScheduleFieldProps) => {
	const {control} = useFormContext<IScheduleForm>();
	const {
		field: {value}
	} = useController({
		name: field,
		control
	});

	const measuresOptions = [durationMeasureKeys.MINUTE, durationMeasureKeys.HOUR];
	const durationMeasures = useDurationMeasures({
		availableOptions: measuresOptions
	});
	const [duration, setDuration] = useState<number>(Number(value));
	const [durationMeasure, setDurationMeasure] = useState<IDurationMeasure>(
		durationMeasures.minute
	);

	// необходимо для установки начальных значений после получения с бэка
	useEffect(() => {
		if (value !== undefined && duration === defaultTimeValues.intervalBetweenSlots) {
			setDuration(Number(value));
		}
	}, [value]);

	useUpdateEffect(() => {
		onSetValue(field, duration);
	}, [duration, onSetValue]);

	return (
		<TimeDurationSelect
			value={value as number}
			dialogError={getDialogError}
			label="Промежуток между слотами"
			duration={duration}
			setDuration={setDuration}
			durationMeasure={durationMeasure}
			setDurationMeasure={setDurationMeasure}
			durationMeasures={durationMeasures}
			durationOptions={durationValuesInMinutes}
			className={className}
			required={required}
		/>
	);
};
