import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {ISavingSchedule} from '@tehzor/tools/interfaces/schedules';
import {ISchedule} from '@tehzor/tools/interfaces/schedules/ISchedule';

/**
 * Добавляет расписание
 *
 * @param fields данные расписания
 */
export const addScheduleRequest = async (fields: ISavingSchedule) => {
	const response = await httpRequests.withToken.post<ISchedule>('schedules', {...fields});
	return response.data;
};
