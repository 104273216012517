import {useController, useFormContext} from 'react-hook-form';
import {editableScheduleFields} from '../constants/editableScheduleFields';
import {IScheduleFieldProps} from '../types/IScheduleFieldProps';
import {ObjectProcessSelect} from '@/shared/ui/Form/ObjectProcessSelect/ObjectProcessSelect';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {IScheduleForm} from '../types/IScheduleForm';
import {ProcessIds} from '@tehzor/tools/interfaces/process/ProcessId';
import {useParticipantsErrorHandler} from '../hooks/useParticipantsErrorHandling';

export const ProcessSelect = ({
	onSetValue,
	field,
	required,
	className,
	errorMessage
}: IScheduleFieldProps) => {
	const {control, watch} = useFormContext<IScheduleForm>();
	const {
		field: {value},
		fieldState: {invalid}
	} = useController({
		name: field,
		control,
		rules: {
			required
		}
	});
	const stage = watch(editableScheduleFields.STAGE_ID) as ObjectStageIds;

	const {clearParticipantsOverlapError} = useParticipantsErrorHandler();

	const handleChange = (v: string) => {
		onSetValue(field, v);
		clearParticipantsOverlapError();
	};

	return (
		<ObjectProcessSelect
			className={className}
			onChange={handleChange}
			value={value as ProcessIds}
			stage={stage}
			label="Процесс"
			required={required}
			disabled={!stage}
			hasError={invalid}
			errorMessage={errorMessage}
		/>
	);
};
