import {useSchedule} from '@/entities/Schedules';
import {useMemo} from 'react';

export const defaultTimeValues = {
	intervalBetweenSlots: 15,
	maximumPreregistrationPeriod: 43_200,
	minimumPreregistrationPeriod: 0,
	slotDuration: 60
};

export const useDefaultScheduleFormData = (scheduleId?: string) => {
	const {data: schedule} = useSchedule(scheduleId);
	return useMemo(
		() => ({
			calendarId: schedule?.calendarId ?? undefined,
			stageId: schedule?.stageId ?? undefined,
			processId: schedule?.processId ?? undefined,
			companyId: schedule?.companyId ?? undefined,
			objectIds: schedule?.objectIds ?? [],
			participantIds: schedule?.participantIds ?? [],
			workingGroupId: schedule?.workingGroupId ?? undefined,
			dateRangeFrom: schedule?.dateRangeFrom ? new Date(schedule.dateRangeFrom) : new Date(),
			dateRangeTo: schedule?.dateRangeTo ? new Date(schedule.dateRangeTo) : undefined,
			simultaneousSlotsCount: schedule?.simultaneousSlotsCount
				? schedule.simultaneousSlotsCount
				: 0,
			slotDuration: schedule?.slotDuration
				? schedule.slotDuration
				: defaultTimeValues.slotDuration,
			intervalBetweenSlots: schedule?.intervalBetweenSlots
				? schedule.intervalBetweenSlots
				: defaultTimeValues.intervalBetweenSlots,
			maximumPreregistrationPeriod: schedule?.maximumPreregistrationPeriod
				? schedule.maximumPreregistrationPeriod
				: defaultTimeValues.maximumPreregistrationPeriod,
			minimumPreregistrationPeriod: schedule?.minimumPreregistrationPeriod
				? schedule.minimumPreregistrationPeriod
				: defaultTimeValues.minimumPreregistrationPeriod,
			maximumOccupiedSlotsNumber: schedule?.maximumOccupiedSlotsNumber
				? schedule.maximumOccupiedSlotsNumber
				: 1
		}),
		[schedule]
	);
};
